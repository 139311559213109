import React from 'react';


import axios from 'axios';
import { useEffect ,useState} from 'react';
import './receipt.css'
// import { MdDelete } from "react-icons/md";
import { BiArchiveIn } from 'react-icons/bi';
import {  AiOutlineEdit } from 'react-icons/ai';
import Receipt from './receipt';
import { IconButton } from '@mui/material';



function Reports(props) {
  const[array,setarray]=useState(null);
  const[formId,setformId]=useState(null);

  const[formdata,setForm]=useState(null)
  const[show,setshow]=useState(false);

  const [order, setOrder] = useState({});
  const getOrderClass = (header) => {
    if (!order[header]) return '';
    return order[header] === 'asc' ? 'fa fa-arrow-up' : 'fa fa-arrow-down';
  };
  const handleSort = (header) => {
    const data = [...formdata.rows];
    const isAsc = order[header] === 'asc';
    data.sort((a, b) => {
      if (a[header] < b[header]) return isAsc ? -1 : 1;
      if (a[header] > b[header]) return isAsc ? 1 : -1;
      return 0;
    });
    setForm({ ...formdata, rows: data });
    setOrder({ [header]: isAsc ? 'desc' : 'asc' });
  };

  useEffect(() => {
    async function fetchForm() {
      
      try {
        const headers = { "Content-Type": "application/json" };
    
       
        const response = await axios.post(
        ` https://mascot-app.dotevolve.net/${props.reportname}/search/grid`,
        
        { headers }
        );
if(response.data!==null){
  
 
  setForm(response.data);
  let array=[];
  Object.getOwnPropertyNames(response.data.headers).forEach(key =>{

    let ar1=[];
    ar1.push(key);
    ar1.push(response.data.headers[key]);
    
      array[response.data.orderBy[key]]=ar1
  }
 
);
setarray(array);


  
                       

}
       }
         catch (error) {

          setForm(null)
         }
    }
    fetchForm();

  }, [props.reportname]);
  function handleupdate(id){
  
    setshow(true);
   setformId( document.getElementById(`${id}MascotFormId`).value);
  
    
   
  }
 console.log(document.getElementsByClassName('hmm3')) ;
  return (
    <div>
    
      {show===true &&
      <div class="container-fluid modal fade " id="exampleModalCenter" 
           
              role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false" >
                <div class="modal-dialog modal-xl text-center " 
                style={{
                    margin:'0',
                    marginRight:'2px',
                    padding:'20px'
                

                }}
                
                >
                    <div class="modal-content  "
                    style={{
                        width:'max-content'
                    }}

                     
                    >
                        <div class="modal-header">
                            <h1 class="modal-title" id="exampleModalLongTitle">Update Reports</h1>


                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={() => { setshow(false) }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body"    >     

                         
                            {
                              formId!==""&&
                                <Receipt formid={props.reportid} updateform={formId}/>
                            }

                        </div>



                        ...

                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal" onClick={() => { setshow(false) }}>Close</button>

                        </div>
                    </div>
                </div>






            </div>}
      {formdata &&(
     <table
     id="data"
 
     className="table-responsive data-table data-table-horizontal data-table-highlight"
 >
        <thead>
            <tr className="hmm3">
              {array.map(([header, title]) => (
                 
                 
               
                <th>
                 <IconButton  color="primary"  aria-label="upload picture" component="label"  
                  key={header}
                  className="main_heading"
                  
                  onClick={() => handleSort(header)}
                >
                  {title}	&nbsp;	&nbsp;
                  <i className={getOrderClass(header)} aria-hidden="true" />
              
                </IconButton>
                </th>
              ))}
            </tr>
          </thead>
      <tbody>
        {formdata.rows.map((row, i) => (
          <tr key={i} className='p-2'
          
        
        >
            {array.map((header, j) => (
              <td key={j} 
              style={{
                width:'max-content',
                padding:'4px',
                
                
            }}>
                 <input  id={`${i}${header[0]}`}
                                                    className='text-center'
                                                    type='text'
                                                    style={{
                                                        width:'max-content',
                                                        paddingLeft:'2px',
                                                        
                                                        
                                                    }}
                                                    
                                                    value={row[header[0]] || ''}
                                                    
                                                    readOnly
                                                />
                
              </td>

            ))}
            <td>
            
            <div className='d-flex flex-row justify-content-center' id={`tdhh${row.id}`} key={row.id} >
                                        <div className='' id={`edit${row.id}`} >

                                            {
                                             <button type="button" class="btn btn-light" data-toggle="modal" data-target="#exampleModalCenter" style={{
                                              borderRadius:"350px"
                                              
                                             }}onClick={()=>{
                                              handleupdate(i)


                                            }} >
                                                <IconButton  color="success"  aria-label="upload picture" component="label"  >
                                                <AiOutlineEdit />
                                                </IconButton>



                                             </button>
                                             

                                            }

                                        </div>
                                        <IconButton  color="success" aria-label="upload picture" component="label"  >
                                        <BiArchiveIn />{" "}
                                                </IconButton>

                                    </div>
              </td>
          </tr>
        ))}
      </tbody>
    </table>)
}
    </div>
  );
}
export default Reports;