import React, { useState, useEffect } from "react";
import "./receipt.css";
import { useRef } from "react";

import axios from "axios";
import Table from "./Table_render";
function Receipt(props) {
  const checksubmission=(event)=>{
    props.checksubmission(event);
    
    }
  

  const p = useRef(0);
  p.current=0;
  function check(formvalue) {
    if (formvalue === "OPEN_ANOTHER_FORM") {
      p.current = 1;
    } 
  }
  
  const [formData, setForm] = useState(null);
  const x = useRef(false);
  
  useEffect(() => {

    
    async function fetchForm() {
      x.current = false;
      try {
        const response = await axios.get(` https://mascot-app.dotevolve.net/form/${props.formid}`
        );
        setForm(response.data);
        if (response.data != null) {
          x.current = true;
        } else {
          x.current = false;
        }
      } catch (error) {}
    }
    fetchForm();
  }, [props]);
 

  return (
    <>
      {" "}
      {x.current && formData && formData.filledByInfos && (
        <div >
          {formData &&
        formData.pages &&
        formData.pages.map((field) =>
          field.inputWidgets.map((help) =>
            help.questions.map(
              (root) =>
                root.display.inputType === "OPEN_ANOTHER_FORM" &&
                check(root.display.inputType)
              
              
              
           )  ) )}
          
          


        {  x.current && formData && formData.filledByInfos && (
         <Table formdata={formData} p={p.current} redirect={props.redirect} total={props.total} updateform ={props.updateform} checksubmission={checksubmission} transaction ={props.transaction}/>)}
          
          
        </div>
      )}
    </>
  );
}
export default Receipt;
