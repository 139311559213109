import React from "react";
import { useState } from "react";
import Receipt from "./components/receipt";
import "./components/receipt.css";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,

  faCaretDown,

} from "@fortawesome/free-solid-svg-icons";
import {RiArticleFill} from 'react-icons/ri'
import {TbReportSearch} from 'react-icons/tb'
import {MdOutlineWork} from 'react-icons/md'
import {AiOutlineSend}  from 'react-icons/ai'
import {HiOutlineLink
} from 'react-icons/hi'
import {BsFillPersonLinesFill} from 'react-icons/bs'
import {GrUserWorker} from 'react-icons/gr'

import Reports from "./components/Reports";
import MaserData from "./components/MaserData";




function App({children}) {
  const[transaction,settranscation]=useState(false);
  const[reportname,setreportname]=useState('');
  const[reportid,setreportid]=useState('');

  const [masterid, setmasterid] = useState("");
  const [materialName, setmaterialName] = useState("");
  const[check,setcheck]=useState(false);
  const [masterredirect, setmasterredirect] = useState(false);

  const [total, setTotal] = useState(null);
  const [showReport, setshowReport] = useState(false);
  const [value, setvalue] = useState(true);
  const [showReceipt, setShowReceipt] = useState(false);
  const [formID, setFormID] = useState("");


  const [configid, setconfigid] = useState("");

 

  const handleLiClick = (id, total) => {
    document.getElementById('sidenav').className="side-nav" ;
    setTotal(total);
    setFormID(id);
    setShowReceipt(true);
    setvalue(!value);
    setshowReport(false);
    settranscation(false);
 
      document.getElementById('reportss').className = "master-data-menu-close";
      document.getElementById('masterdataa').className = "master-data-menu-close";
   
  };

  return (
    <>
  


      
  
      <div className="fluid-container navbar1">
        <div className="navbar-hamburger" onClick={(e) => { ;
        if(document.getElementById('sidenav').className==="side-nav"){
          document.getElementById('sidenav').className="side-nav open"

        }else{
          document.getElementById('sidenav').className="side-nav"
          document.getElementById('reportss').className = "master-data-menu-close";
      document.getElementById('masterdataa').className = "master-data-menu-close";
        }

        

        
      
      }}
      
      >
          
           
         
            <div className="h" >
              {" "}
             <FontAwesomeIcon icon={faBars} />{" "}
           </div>

           {/* <div className="h" >
              {" "}
             <FontAwesomeIcon icon={faTimes} />{" "}
           </div> */}
          
        
        </div>
        <div className="navbar-brand ">Mascot Footwear</div>
        { (
          <div id={"sidenav"} className={`side-nav`}>
          
              <li
                onClick={() => {
                  handleLiClick(
                    "dc70a0e1-9cf3-4b14-8e3f-781cad7aaae8",
                    "total"
                  );
                  settranscation(false);
                  setmasterredirect(false);
                }}
              >
                
                <MdOutlineWork  style={{color:'black' ,marginRight:'10px'
                    
                    
                  }}/>
                Jobs
              </li>
              <li
                onClick={() => {
                  handleLiClick(
                    "09e63d34-5869-475d-bbeb-465dd4e3600f",
                    "transaction"
                  );
                  setmasterredirect(false);
                  settranscation(true);
                }}
              >
                
                <MdOutlineWork  style={{color:'black' ,marginRight:'10px'
                    
                    
                  }}/>
                Transactions
              </li>
              <li
                onClick={() => {
                  // handleLiClick("7a8d9a1e-9e56-4f4a-aee1-ac76426581d3");
                  // setmasterredirect(false);
                  handleLiClick("7a8d9a1e-9e56-4f4a-aee1-ac76426581d3");
                  setmasterid("7a8d9a1e-9e56-4f4a-aee1-ac76426581d3");
                  setmasterredirect(true);
                  setShowReceipt(false);
                  setcheck(true);
                  setmaterialName("Article");
                  setconfigid("articlesList");
                  
                }}
              >
                 <RiArticleFill  style={{color:'black' ,marginRight:'10px'
                    
                    
                  }}/>
                Article
              </li>
              <li
                onClick={() => {
                  // handleLiClick("d2d2b443-fbe3-470b-be12-db5ceb61679e");
                  // setmasterredirect(false);
                  handleLiClick("d2d2b443-fbe3-470b-be12-db5ceb61679e");
                  setmasterid("d2d2b443-fbe3-470b-be12-db5ceb61679e");
                  setmasterredirect(true);
                  setShowReceipt(false);
                  setcheck(true);
                  setmaterialName("Workers");
                  setconfigid("workersList");
                }}
              >
               <GrUserWorker  style={{color:'black' ,marginRight:'10px'
                    
                    
                  }}/>
                Workers
              </li>
              <li
                onClick={() => {
                  // handleLiClick("bd0d0160-c1a7-4918-b9a7-2e88a2af09bf");
                  // setmasterredirect(false);
                  handleLiClick("bd0d0160-c1a7-4918-b9a7-2e88a2af09bf");
                  setmasterid("bd0d0160-c1a7-4918-b9a7-2e88a2af09bf");
                  setmasterredirect(true);
                  setcheck(true);
                  setShowReceipt(false);
                  setmaterialName("Customer");
                  setconfigid("customersList");
                }}
              >
                <                BsFillPersonLinesFill
  style={{color:'black' ,marginRight:'10px'
                    
                    
                  }}/>
                Customers
              </li>
            
  <li className="master-data-header" onClick={() => {
     document.getElementById('masterdataa').className="master-data-menu-close";
    if (document.getElementById('reportss').className === "master-data-menu1-open") {
      document.getElementById('reportss').className = "master-data-menu1-close";
  
      
       
    

    } else {
      document.getElementById('reportss').className = "master-data-menu1-open"
      
    }
  }}>
    <TbReportSearch style={{color:'black', marginRight:'10px'}} />
    Reports&nbsp;&nbsp;&nbsp;&nbsp;
    <FontAwesomeIcon id ='icon1'icon={faCaretDown} />
  </li>
  {(
    <div className="master-data-menu1-close" id={'reportss'}>
                    <li
                     onClick={() => {
                      document.getElementById('reportss').className="master-data-menu1-close";
                      setmasterredirect(false);
                      setshowReport(true);
                      setShowReceipt(false);
                      document.getElementById('sidenav').className="side-nav" ;
                      setreportname('article');
                      
                            
                    setreportid('7a8d9a1e-9e56-4f4a-aee1-ac76426581d3')
                    
                    }}
                    >
                      <                   AiOutlineSend
  style={{color:'black' ,marginRight:'10px'
                    
                    
                  }}/> Articles
                    </li>
                    <li
                   onClick={() => {
                    setmasterredirect(false);
                    setshowReport(true);
                    setShowReceipt(false);
                    document.getElementById('reportss').className="master-data-menu-close";
                    document.getElementById('sidenav').className="side-nav" ;
                    setreportname('customer');
                   
                    setreportid('bd0d0160-c1a7-4918-b9a7-2e88a2af09bf')
                  
                  }}
                    >
                    <                   AiOutlineSend
  style={{color:'black' ,marginRight:'10px'
                    
                    
                  }}/>    Customers
                    </li>
                    <li
                    onClick={() => {
                      setmasterredirect(false);
                      setshowReport(true);
                      setShowReceipt(false);
                      document.getElementById('sidenav').className="side-nav" ;
                      setreportname('task');
                      setreportid('dc70a0e1-9cf3-4b14-8e3f-781cad7aaae8')
                      document.getElementById('reportss').className="master-data-menu-close";
                     
                    
                    }}
                    >
                   <                   AiOutlineSend
  style={{color:'black' ,marginRight:'10px'
                    
                    
                  }}/>     Tasks
                    </li>
                    <li
                       onClick={() => {
                        setmasterredirect(false);
                        setshowReport(true);
                        setShowReceipt(false);
                        document.getElementById('sidenav').className="side-nav" ;
                        setreportname('worker');
                        setreportid('d2d2b443-fbe3-470b-be12-db5ceb61679e')
                        document.getElementById('reportss').className="master-data-menu-close";
                      
                      }}
                    >
                <                   AiOutlineSend
  style={{color:'black' ,marginRight:'10px'
                    
                    
                  }}/>        Workers
                    </li>
                    
                  </div>
                )}
              
            

              <li className="master-data-li">
                <div className="master-data-header"  onClick={()=>{
                   document.getElementById('reportss').className = "master-data-menu1-close";
                  
                  if(document.getElementById('masterdataa').className==="master-data-menu-open"){
                    document.getElementById('masterdataa').className="master-data-menu-close";
                  }else{
                    document.getElementById('masterdataa').className="master-data-menu-open"
                  }
                  
                  
                  }}>
                <                   HiOutlineLink
  style={{color:'black' ,marginRight:'10px'
                    
                    
                  }}/>
                
                  Master data&nbsp;&nbsp;&nbsp;&nbsp;
                  <FontAwesomeIcon
                    icon={faCaretDown }
                  />
                  
                </div>
                { (
                  <div className="master-data-menu-close" id="masterdataa">
                    <li
                      onClick={() => {
                        document.getElementById('masterdataa').className="master-data-menu-close"
                        handleLiClick("d2d2b443-fbe3-470b-be12-db5ceb61679e");
                        setmasterid("d2d2b443-fbe3-470b-be12-db5ceb61679e");
                        setmasterredirect(true);
                        setShowReceipt(false);
                        setmaterialName("Material");
                        setconfigid("materialsList");
                        setcheck(false);
                      }}
                    >
                    <                   AiOutlineSend
  style={{color:'black' ,marginRight:'10px'
                    
                    
                  }}/>    Material
                    </li>
                    <li
                      onClick={() => {
                        document.getElementById('masterdataa').className="master-data-menu-close"
                        
                        handleLiClick("5d95618c-7a50-4b3d-bef1-6ef4c2980f1c");
                        setmasterid("5d95618c-7a50-4b3d-bef1-6ef4c2980f1c");
                        setmasterredirect(true);
                        setShowReceipt(false);
                        setmaterialName("Color");
                        setconfigid("colorsList");
                        setcheck(false);
                      }}
                    >
                  <                   AiOutlineSend
  style={{color:'black' ,marginRight:'10px'
                    
                    
                  }}/>      Color
                    </li>
                    <li
                      onClick={() => {
                        document.getElementById('masterdataa').className="master-data-menu-close"
                        handleLiClick("d2d2b443-fbe3-470b-be12-db5ceb61679e");
                        setmasterid("d2d2b443-fbe3-470b-be12-db5ceb61679e");
                        setmasterredirect(true);
                        setShowReceipt(false);
                        setmaterialName("Sole");
                        setconfigid("solesList");
                        setcheck(false);
                      }}
                    >
                   <                   AiOutlineSend
  style={{color:'black' ,marginRight:'10px'
                    
                    
                  }}/>     Sole
                    </li>
                    
                  </div>
                )}
              </li>
            
          </div>
        )}
      </div>
      <div className="flexbox table-responsive justify-content-center"
    style={{
      width:"auto"
    }}
    
    >

      <div className="responsive-table disp">
        {showReceipt ? (
          
          <Receipt key={formID} formid={formID} total={total} change={value} transaction={transaction} />
        ) : (
          <h1>New Job</h1> &&
          showReport === false &&
          masterredirect === false && (
            transaction===false &&
            <Receipt
              formid={"dc70a0e1-9cf3-4b14-8e3f-781cad7aaae8"}
              total={"total"}
            />
          )
        )}
        {showReport === true  && <Reports  reportname={reportname} reportid={reportid}/>}
        {masterredirect === true && (
          <MaserData
            formid={masterid}
            materialName={materialName}
            configid={configid}
            check={check}
          />
        )}
       
      </div>
    </div>
    </>
  );
}
export default App;