import React from "react";
import { useState, useEffect } from "react";
import Dropper from "./dropper";
import "./receipt.css";

function Widget(props) {
  const [formdata, setformdata] = useState(null);
  const[update_form,setupdate_form]=useState(null);
  // const[main_obj,setmain_obj]=useState({});
  const[return1,setreturn]=useState(false);

  
  if(return1===true){
    props.checksubmission(return1);
    setreturn(false);
  }

  

  useEffect(() => {
    if (props.formdata != null) {
      setformdata(props.formdata);
    }
  }, [props.formdata]);

  useEffect(()=>{
    setupdate_form(props.update_form);
    
    if (props.update_form  && formdata) {
      if (props.update_form.answers) {
        for (let k = 0; k < props.update_form.answers.length; k++) {
          for (let i = 0; i < formdata.length; i++) {
            for (let j = 0; j < formdata[i].questions.length; j++) {
              //  console.log(formdata[i].questions.length)
              if (
                document.getElementById(
                  `${formdata[i].questions[j].display.label}${j}`
                ) !== null
              ) {
                for (let x = 0; x < props.update_form.answers.length; x++) {
                  if (
                    formdata[i].questions[j].fieldNo ===
                    props.update_form.answers[k].fieldNo
                  ) {
                    document.getElementById(
                      `${formdata[i].questions[j].display.label}${j}`
                    ).value = props.update_form.answers[k].value;
                  }
                }
              }
            }
          }
        }
      }
    }
  },[props.update_form,update_form,formdata])


  useEffect(() => {
    // console.log(props.update_form)

     if (props.obj) {
      async function handlesubmission() {

        if(props.obj && props.update_form!==null && formdata ){


          if (update_form && formdata) {
            if (update_form.answers) {
              for (let k = 0; k < update_form.answers.length; k++) {
                for (let i = 0; i < formdata.length; i++) {
                  for (let j = 0; j < formdata[i].questions.length; j++) {
                    //  console.log(formdata[i].questions.length)
                    if (
                      document.getElementById(
                        `${formdata[i].questions[j].display.label}${j}`
                      ) !== null
                    ) {
                    
                        if (
                          formdata[i].questions[j].fieldNo ===
                        update_form.answers[k].fieldNo
                        ) {
                          update_form.answers[k].value=
                          document.getElementById(
                            `${formdata[i].questions[j].display.label}${j}`
                          ).value
                         
                        
                      }
                    }
                  }
                }
              }
            }
            try {
              const submission = await fetch(
                  ` https://mascot-app.dotevolve.net/formFilled/${props.update_form.id}`,
                  {
                      method: "PUT",
                      headers: {
                        "Content-Type": "application/json",
                    },
                     
                      body: JSON.stringify(update_form),
                  }
              );
              if (!submission.ok) {
                  throw new Error(submission.statusText);
              }else{
                
                setTimeout(() => {
                  for (let i = 0; i < formdata.length; i++) {
                    for (let j = 0; j < formdata[i].questions.length; j++) {
                      if (
                        document.getElementById(
                          `${formdata[i].questions[j].display.label}${j}`
                        ) !== null
                      ) {
                        document.getElementById(
                          `${formdata[i].questions[j].display.label}${j}`
                        ).value="";
        
        
        
                      }
        
        
        
                    }
                  }
                  
                 }, 3000);
              } 


          } catch (error) {
              console.log(error)
          }
          
          
          
          
          
          

          
        }


        }
        
      }

      handlesubmission();
    }
  }, [formdata, props.obj,update_form,props.update_form]);


  useEffect(() => {
    // console.log(props.update_form)

     if (props.obj && props.update_form===null) {
      async function handlesubmission() {

        


  
       


      
      
        
   
        for (let i = 0; i < formdata.length; i++) {
          for (let j = 0; j < formdata[i].questions.length; j++) {
            //  console.log(formdata[i].questions.length)
            if (
              document.getElementById(
                `${formdata[i].questions[j].display.label}${j}`
              ) !== null
            ) {
              let ref_obj = {
                fieldNo: formdata[i].questions[j].fieldNo,
                value: document.getElementById(
                  `${formdata[i].questions[j].display.label}${j}`
                ).value,
                type: formdata[i].questions[j].display.inputType,
                label: formdata[i].questions[j].display.label,
                casePreserve: null,
                parentWidgetNo: 0,
                altValue: "",
                configId: formdata[i].questions[j].configId,
              };

              props.obj.answers.push(ref_obj);
            }
          }
        }

        try {
          const response = await fetch(
            " https://mascot-app.dotevolve.net/formFilled",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(props.obj),
            }
          );
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          const jsonResponse = await response.json();

          //  console.log(jsonResponse);
          if (jsonResponse !== null) {
            const endpoint = jsonResponse.formTypeEnum.toLowerCase();
            try {
              const response = await fetch(
                ` https://mascot-app.dotevolve.net/${endpoint}/createViaFormFilledId`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "text/plain",
                  },
                  body: `${jsonResponse.id}`,
                }
              );
              if (!response.ok) {
                throw new Error(response.statusText);
              }else{
           setreturn(true);
           setTimeout(() => {
            for (let i = 0; i < formdata.length; i++) {
              for (let j = 0; j < formdata[i].questions.length; j++) {
                if (
                  document.getElementById(
                    `${formdata[i].questions[j].display.label}${j}`
                  ) !== null
                ) {
                  document.getElementById(
                    `${formdata[i].questions[j].display.label}${j}`
                  ).value="";
  
  
  
                }
  
  
  
              }
            }
            
           }, 3000);
       
          
              }
              const jsonResponse2 = await response.json();

               console.log(jsonResponse2);
            } catch (error) {
              console.log(error);
            }
          }
        } catch (error) {

          console.log(error);
        }
      }
      

      handlesubmission();
    }
    
  }, [formdata, props.obj,update_form,props.update_form]);

  return (
    formdata &&
    formdata.map(
      (help) =>
        help.headerQuestion &&
        help.headerQuestion.display.inputType === "HEADING" && (
          <div className="main">
            <div className="Main_heading ">
              {help.headerQuestion &&
                help.headerQuestion.display.inputType === "HEADING" && (
                  <h1 style={{ marginBottom: "1rem" }}>
                    {help.headerQuestion.display.label}
                  </h1>
                )}
              <div>
                <div className="row">
                  {help.questions.map((field, index) =>
                    field.display.inputType === "DROPDOWN" ? (
                      <div
                        key={index}
                        className="d-flex flex-column"
                        style={{ marginLeft: "2rem" }}
                      >
                        <label className="p-1 mx-auto">
                          {field.display.label}
                        </label>
                        <select
                          id={`${field.display.label}${index}`}
                          className="dropdown"
                          htmlFor={field.display.label}
                        >
                          <option value="Not_selected" selected>
                            Select your {field.display.label}{" "}
                          </option>
                          <Dropper configid={field.configId} />
                        </select>
                      </div>
                    ) : (
                      field.display.inputType === "TEXT" && (
                        <div
                          key={index}
                          className="d-flex flex-column"
                          style={{ marginLeft: "2rem" }}
                        >
                          <label className="p-1">{field.display.label}</label>
                          <input
                            id={`${field.display.label}${index}`}
                            style={{ backgroundColor: "#e7f1f1" }}
                            type="text"
                            className="p-2"
                          />
                          <p>
                            
                          </p>
                        </div>
                      )
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        )
    )
  );
}

export default Widget;
