import React from 'react';
import { useState, useEffect } from 'react';

import './masterdata.css';
import axios from 'axios';
import { AiOutlineMinusCircle, AiOutlineEdit, AiFillPlusCircle } from 'react-icons/ai';
import { MdDelete } from "react-icons/md";
import { FcCheckmark } from 'react-icons/fc';
import Modal from './Modal';
import "./receipt.css"
import IconButton from '@mui/material/IconButton';
import Receipt from './receipt';
import "./loader.css";
// import { BsDisplay } from 'react-icons/bs';
function MasterData(props) {

    const [changer, setchanger] = useState(null);
    const [rows, setRows] = useState([]);
    const [formdata, setForm] = useState(null);
  const [deleteid,setdeleteid]=useState(null);




    const checksubmission=(event)=>{
        if(event===true){
            console.log("one time");
            
            
        }
        
        }



    useEffect(() => {
        const fetchForm=async()=> {
            try {
                const headers = { "Content-Type": "application/json" };
                if (props.configid != null) {
                    const body = {
                        providerId: "mascot-footwear",
                        configId: `${props.configid}`,
                    };
                    const response = await axios.post(
                        " https://mascot-app.dotevolve.net/fieldConfig/search",
                        body,
                        { headers }
                    );

                    setForm(response.data);

                    if (response.data != null) {


                        let array = [];
                        for (let i = 0; i < response.data.length; i++) {
                            let obj = {};

                            let push_obj = response.data[i];

                            for (let j = 1; j <= 3; j++) {
                                if (j === 1) {
                                    obj[`col${j}`] = response.data[i].value;
                                }
                                if (j === 2) {
                                    obj[`col${j}`] = response.data[i].label;
                                }
                            }
                            array.push({
                                push_obj: push_obj,
                                updateid: "",
                                showcheck: false,
                                id: i + 1,
                                ...obj,
                            });

                            setRows(array);
                        }
                    }
                }
            } catch (error) { }
        }
        fetchForm();
        setchanger(null);
    }, [props,changer]);



    const [showadd, setshowadd] = useState(false);
    const [formid, setformid] = useState("");

    useEffect(() => {
        setformid(props.formid);

    }, [formid, props.formid]);
    const handleedit = (id, value) => {


        if (value.length === 0) {
            document.getElementById(`error${id}col${2}`).innerHTML = "Field can't be empty"
            document.getElementById(`focus${id}col${2}`).style.border = "thin solid red";
        } else {
            document.getElementById(`error${id}col${2}`).innerHTML = ""
            document.getElementById(`focus${id}col${2}`).style.border = "";

        }


        const updatedRows = rows.map(row => {

            if (row.id === id) {


                return {
                    ...row, col2: value

                };

            }
            return row;
        });


        setRows(updatedRows);
        rows[id - 1].push_obj.label = value;




    };
    const handleClick = () => {

        setshowadd(!showadd);


    };

    const handlerefresh=()=>{
        document.getElementById('loader').className='loading';
        setTimeout(() => {
            document.getElementById('loader').className='';
            
        }, 2000);
        const fetchForm=async()=> {
            try {
                const headers = { "Content-Type": "application/json" };
                if (props.configid != null) {
                    const body = {
                        providerId: "mascot-footwear",
                        configId: `${props.configid}`,
                    };
                    const response = await axios.post(
                        " https://mascot-app.dotevolve.net/fieldConfig/search",
                        body,
                        { headers }
                    );

                    setForm(response.data);

                    if (response.data != null) {


                        let array = [];
                        for (let i = 0; i < response.data.length; i++) {
                            let obj = {};

                            let push_obj = response.data[i];

                            for (let j = 1; j <= 3; j++) {
                                if (j === 1) {
                                    obj[`col${j}`] = response.data[i].value;
                                }
                                if (j === 2) {
                                    obj[`col${j}`] = response.data[i].label;
                                }
                            }
                            array.push({
                                push_obj: push_obj,
                                updateid: "",
                                showcheck: false,
                                id: i + 1,
                                ...obj,
                            });

                            setRows(array);
                        }
                    }
                }
            } catch (error) { }
        }
        fetchForm();
        

    }
    async function finalsubmit(id) {
        if (rows[id - 1].col2.length === 0) {
            let element = document.getElementById(`focus${id}col${2}`);
            element.focus();

        }
        if (rows[id - 1].col2.length !== 0) {





            let element = document.getElementById(`focus${id}col${2}`);


            element.readOnly
                = true;





            let p = rows[id - 1].push_obj.id;
            console.log(`hmm ${p}`);
            try {
                const response = await axios.get(` https://mascot-app.dotevolve.net/fieldConfig/${p}`
                );

                if (response.data != null) {
                    response.data.label = rows[id - 1].col2;
                    try {
                        const submission = await fetch(
                            ` https://mascot-app.dotevolve.net/fieldConfig/${p}`,
                            {
                                method: "PUT",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify(response.data),
                            }
                        );
                        if (!submission.ok) {
                            throw new Error(submission.statusText);
                        } else {
                            const updatedRows = rows.map(row => {
                                if (row.id === id) {
                                    return { ...row, showcheck: false };
                                }
                                return row;
                            });
                            setRows(updatedRows);
                            document.getElementById(`edit${id}`).style.fontSize = "24px";




                        }


                    } catch (error) {
                        console.log(error)
                    }
                }
            } catch (error) { }


        }
    }

    function dostuff(id) {

        const updatedRows = rows.map(row => {
            if (row.id === id) {
                return { ...row, showcheck: true };
            }
            return row;
        });
        setRows(updatedRows);



        document.getElementById(`edit${id}`).style.fontSize = "28px";





        let element = document.getElementById(`focus${id}col${2}`);

        element.removeAttribute('readOnly')
        element.focus();








    }

    async function deletefunc(id) {
        document.getElementById('loader').className="loading";

        let p = rows[id - 1].push_obj.id;
        try {
            const submission = await fetch(
                ` https://mascot-app.dotevolve.net/fieldConfig/${p}`,
                {
                    method: "DELETE",
                }
            );
            if (!submission.ok) {
                setTimeout(() => {
                    setRows(rows.filter((row) => row.id !== id));
                    document.getElementById('loader').className="";
                    
                }, 2000);

                throw new Error(submission.statusText);
               
            } else {
                setTimeout(() => {
                    setRows(rows.filter((row) => row.id !== id));
                    document.getElementById('loader').className="";
                    
                }, 2000);
               
            }




        } catch (error) {
            document.getElementById('loader').className="";
    
        }




    }


    return (
        <>
         
	
	


         <div id="loader" class=""></div>

<div class="content">
{/* <div class="hidden">
  <strong>Success!</strong> Indicates a successful or positive action.
</div> */}
<div id="myModal" class="modal fade">
	<div class="modal-dialog modal-confirm">
		<div class="modal-content">
			<div class="modal-header flex-column">
									
				<h4 class="modal-title w-100">Are you sure?</h4>	
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
			</div>
			<div class="modal-body">
				<p>Do you really want to delete these records? This process cannot be undone.</p>
			</div>
			<div class="modal-footer justify-content-center">
				<button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
				<button type="button" class="btn btn-danger" data-dismiss="modal" onClick={()=>{deletefunc(deleteid)}} >Delete</button>
			</div>
		</div>
	</div>
</div>  
        

        <div className='container'>
            
            <div className='main'>
                
                <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter" onClick={() => handleClick()} >
                    <div>{!showadd ? <div className='buttoninside' style={{
                        padding: "1px"
                    }} ><div >
                            <AiFillPlusCircle /></div> <div className='text-center'>Add {props.materialName}</div>


                    </div> : <div className='buttoninside'><div>
                        <AiOutlineMinusCircle />{" "}</div> <div>Minimize</div></div>

                    }
                    </div>
                </button>
            </div>

            <div class="responsive-table modal fade " id="exampleModalCenter" 
            //  style={{
            
            //     width: 'max-content',


            // }}
            // style={{
            //     marginRight:'1rem',
                
            //     position:'fixed',
            
            
            // }}
              role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false" >
                <div class="modal-dialog modal-xl text-center " 
                style={{
                    margin:'0',
                    marginRight:'2px',
                    padding:'20px'
                

                }}
                
                >
                    <div class="modal-content  "
                    style={{
                        width:'max-content'
                    }}

                     
                    >
                        <div class="modal-header">
                            <h1 class="modal-title" id="exampleModalLongTitle">Add {props.materialName} Field</h1>


                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={() => { handleClick();setchanger({}) }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body"    >     

                            {
                                showadd === true && props.check === false && <Modal item={props.materialName} onchildclick={handlerefresh}/>

                            }
                            {
                                showadd === true && props.check === true && <Receipt
                                    formid={props.formid} 
                                    checksubmission={checksubmission}

                                />
                            }

                        </div>



                        ...

                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal" onClick={() => { handleClick(); setchanger({}) }}>Close</button>

                        </div>
                    </div>
                </div>






            </div>
            <div className="mx-auto flex-box justify-content-center" >
                <table
                    id="data"
                
                    className="table-responsive data-table data-table-horizontal data-table-highlight"
                >

                    <tbody>
                        <tr  key={0}>
                            <th className="main_heading" style={{color:"black",
        
        
        }}>
                                Id
                            </th>
                            <th className="main_heading" style={{color:"black",

        
        }}>
                                {props.materialName}
                            </th>
                            <th className="main_heading" style={{color:"black",
        
        
        }}>
                                Actions
                            </th>
                        </tr>
                        {formdata && rows.map((row) => (
                            <tr  key={row.id} id={row.id}>
                                {Array.from(
                                    { length: 2 },
                                    (_, i) => {
                                        const columnIndex = i + 1;
                                        let inputType = "text";
                                        let color=false;
                                        if(props.materialName==='Color'){
                                            color=true
                                        }


                                        // }
                                        return (


                                            <td className='text-center' key={`col${columnIndex}`}>
                                                <div className='row mx-auto my-auto'>
    {columnIndex === 2 && color===true &&
                                                 
                                                 <input type="text" style={{
                                                     width:'30px',
                                                     height:'40px',
                                                     backgroundColor:`${row[`col${1}`]}`
                                               
                                                  
                                                 }}
                                                 
                                                 disabled/>
                                            
                                                     

                                            
                                    }
                                             <div class="d-flex flex-column">
                                                <input
                                                    className='p-2 text-center'
                                                    type={inputType}
                                                    style={{
                                                        width:'min-content',
                                                        paddingLeft:'2px',
                                                        
                                                        
                                                    }}
                                                    id={`focus${row.id}col${columnIndex}`}
                                                    value={row[`col${columnIndex}`]}
                                                    onChange={(e) => { handleedit(row.id, e.target.value); }}
                                                    readOnly
                                                />
                                                  {columnIndex === 2 && <p
                                                    className=" hey1"
                                                    
                                                    style={{
                                                        width:'max-content',
                                                        paddingLeft:'2px',
                                                        paddingRight:'2px',
                                                    
                                                        fontSize: '10px'
                                                    }}
                                                    
                                                    id={`error${row.id}col${columnIndex}`}
                                                ></p>}
                                                </div>
                                                </div>

                                              
                                            </td>

                                        );
                                    }
                                )}
                                <td key={`col${3}`}
                                // style={{
                                //     width:'max-content',
                                //     paddingLeft:'2px',
                                //     paddingRight:'2px',
                                //     margin:'1px'
                                // }}
                                
                                >



                                    <div className='d-flex flex-row justify-content-center' id={`tdhh${row.id}col${3}`} key={row.id} >
                                        <div className='p-1.2 texx' id={`edit${row.id}`} style={{ color: "#066816" }}>

                                            {
                                                row.showcheck ? 
                                                <IconButton color="success" aria-label="upload picture" component="label" >
             
  <FcCheckmark onClick={() => { finalsubmit(row.id) }} />
  </IconButton>


                                                
                                                 : 
                                                 <IconButton color="success" aria-label="upload picture" component="label" >
                                                 <AiOutlineEdit onClick={() => { dostuff(row.id) }} />
                                                 </IconButton>


                                            }

                                        </div>
                                        <div className='p-1.2 texx' id={`delete${row.id}`} style={{ color: "#7a2121" }}>
                                        <a href="#myModal" class="trigger-btn" data-toggle="modal"> <IconButton color="error" aria-label="upload picture" component="label" >
  
  <MdDelete onClick={() => {setdeleteid(row.id)}} />{""}
</IconButton></a>
                                       
                                    
                                           
                                            
                                        </div>
                                    </div>

                                </td>
                            </tr>
                        )

                        )}
                    </tbody>
                </table>
            </div>
        </div>
       </div>
       </>

 );

}

export default MasterData