import React, { useState, useEffect } from "react";
import "./receipt.css";
import Header from "./header";
import { MdDelete } from "react-icons/md";
import Receipt from "./receipt";
import Heading from "./head_description";
import Dropper from "./dropper";
import Widget from "./Widget";
import axios from "axios";
import LoadingButton from '@mui/lab/LoadingButton';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import SendIcon from '@mui/icons-material/Send';
import { IconButton } from "@mui/material";
import DataSaverOnOutlinedIcon from '@mui/icons-material/DataSaverOnOutlined';
import "./loader.css"







// import {BsPlusLg} from "react-icons/bs"
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function Table(props) {
  const[names,setnames]=useState([]);
  const[obj,setobj]=useState();
  
  
  async function multirender(configid){
    if(names.length===0){
  

  
  
    try {
      const headers = { "Content-Type": "application/json" };
      const body = {
        providerId: "mascot-footwear",
        configId: configid,
      };
      const response = await axios.post(
        " https://mascot-app.dotevolve.net/fieldConfig/search",
        body,
        { headers }
      );
  
      let obj1={};
  const name=[];
      if (response.data != null) {
      
        response.data.map((field)=>(
          name.push(field.label)
// eslint-disable-next-line no-sequences
,
         obj1[field.label]=field.value
  
  
        ))
        setnames(name);
        setobj(obj1);

  
      }
    } catch (error) {}
  
  
  }  
  }
  const checksubmission=(event)=>{
    setTimeout(() => {
      if(event ===true){
        setPersonName([]);
        props.checksubmission(event);
        
      }
      document.getElementById('loader').className="";
      
    }, 
    3000);
  
  }


  const [personName, setPersonName] =useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    
      for (let i = 0; i < 1; i++) {
      
        
        for (let j = 1; j <= props.formdata.filledByInfos.length; j++) {
          let sub=[];

        if(props.formdata.filledByInfos[j - 1].configId==="departmentsList"){
          for(let k=0;k<value.length;k++){
            sub.push(obj[value[k]]);

          }

          rows[0][`col${j}`]= sub.join(",");

         
           
        }
      
        console.log(rows);
         
        }

    }
  
  };
  const [widgetsub, setwidgetsub] = useState(null);
  const[updateform,setupdateform]=useState(null);
  const [Main_total, setMain] = useState(null);
  const[loading,setloading]=useState(false);


  const [rows, setRows] = useState([]);
  const [Submit, setsubmit] = useState("Submit");
  const [total_row, setTotal] = useState({
    col1: "",
    col2: "",
    col3: "",
    col4: "",
    col5: "",
    col6: "",
    col7: "",
    col8: "",
    col9: "",
  });

  
  

  
  
  
  
  
  
  
  useEffect(() => {
    if (props.formdata !== null && props.formdata.filledByInfos) {
      let array = [];
      if(props.updateform){
       async function fetchForm() {
    
      
      try {
        const response = await axios.get(` https://mascot-app.dotevolve.net//formFilled/${props.updateform}`
        );
        
        if (response.data != null) {
          setupdateform(response.data)
        console.log(response.data);
        for (let i = 0; i < 1; i++) {
          let obj = {};
          
          for (let j = 1; j <= props.formdata.filledByInfos.length; j++) {
            for(let x=0;x<response.data.answers.length;x++){
              if(props.formdata.filledByInfos[j - 1].fieldNo===response.data.answers[x].fieldNo){
                obj[`col${j}`]=response.data.answers[x].value
              }

            }
         
          }
         
         
          array.push({
            id: i + 1,
            ...obj,
          });
  
          setRows(array);
        }
        }
      } catch (error) {}
    }
    
      
   



  fetchForm();}else{

    for (let i = 0; i < 1; i++) {
      let obj = {};
      let x = 0;
      for (let j = 1; j <= props.formdata.filledByInfos.length; j++) {
        obj[`col${j}`] = props.formdata.filledByInfos[j - 1].defaultValue;
       
        x += parseInt(props.formdata.filledByInfos[j - 1].defaultValue) || 0;
      }
     
      if (props.total === "total") {
        obj[`col${props.formdata.filledByInfos.length + 1}`] = x;
      }
      array.push({
        id: i + 1,
        ...obj,
      });

      setRows(array);
    }
  }
   
    }
  }, [props.formdata, props.total,props.value,props.updateform]);

  useEffect(() => {
    if (props.formdata !== null && props.formdata.filledByInfos) {
      let a = 1;
      let obj = {};

      for (let i = 1; i <= props.formdata.filledByInfos.length; i++) {
        if (rows.length === 0) {
          for (let i = 1; i <= 7; i++) {
            obj[`col${i}`] = 0;
          }
        } else {
          let flag = 0;
          let z = 0;
          for (let p = 0; p < rows.length; p++) {
            if (
              props.formdata.filledByInfos[i - 1].display.inputType === "NUMBER"
            ) {
              z += parseInt(rows[p][`col${i}`]) || 0;
              flag = 1;
            }
          }

          if (flag === 1) {
            obj[`col${a++}`] = z;
          }
        }
      }

      setTotal(obj);
    }
  }, [props.formdata, rows]);

  useEffect(() => {
    if (props.total === "total") {
      let x = 0;
      for (let p = 0; p < rows.length; p++) {
        x +=
          parseInt(rows[p][`col${props.formdata.filledByInfos.length + 1}`]) ||
          0;
      }

      setMain(x);
    }
  }, [rows, props.formdata, total_row, props.total]);

  const [value1, setvalue] = useState("");

  function addRow() {
    let newRow = { id: Date.now() };
    let x = 0;
    if (props.formdata && props.formdata.filledByInfos.length) {
      for (let i = 1; i <= props.formdata.filledByInfos.length; i++) {
        newRow[`col${i}`] = props.formdata.filledByInfos[i - 1].defaultValue;
        x += parseInt(props.formdata.filledByInfos[i - 1].defaultValue) || 0;
      }
    }
    setRows([...rows, newRow]);
    if (props.total === "total") {
      newRow[`col${props.formdata.filledByInfos.length + 1}`] = x;
    }
  }

  function deleteRow(id) {
    setRows(rows.filter((row) => row.id !== id));
  }

  async function handleSubmit(id) {

  
   
   
    if (props.formdata.id === id) {
      

    
     

// Create a new div element


// Add the new div element as a child of the parent div element

      let idArray = [];
      setvalue("");
    setloading(true);
  

      let error = false;
      for (let i = 0; i < rows.length; i++) {
        for (let j = 1; j <= props.formdata.filledByInfos.length; j++) {
          if(document.getElementById(
            `td${rows[i].id}col${j}${props.formdata.id}`
          )!==null){
          document.getElementById(
            `td${rows[i].id}col${j}${props.formdata.id}`
          ).disabled=true;}
          
          if (!rows[i][`col${j}`]) {
            if(props.formdata.filledByInfos[j-1].validation.errorMessage!==null){
            document.getElementById(
              `${rows[i].id}col${j}${props.formdata.id}`
            ).innerText = props.formdata.filledByInfos[j-1].validation.errorMessage;

            document.getElementById(
              `td${rows[i].id}col${j}${props.formdata.id}`
            ).style.border = "thin solid red";
            
            error = true;

            setTimeout(() => {
             
              setloading(false)
            
              
            }, 1000);
            setTimeout(() => {
              setloading(false)
              setsubmit("Submit");
              for (let i = 0; i < rows.length; i++) {
                for (let j = 1; j <= props.formdata.filledByInfos.length; j++) {

                  if(document.getElementById(
                    `td${rows[i].id}col${j}${props.formdata.id}`
                  )!==null){
                  document.getElementById(
                    `td${rows[i].id}col${j}${props.formdata.id}`
                  ).disabled=false;
                }}}
              
            }, 1900);

          
          

          }}
        }
      }

      for (let i = 0; i < rows.length; i++) {
        let formTypeEnum="";
        if(props.formdata.formName==="Task Details"){
          formTypeEnum="TASK_DETAILS"
        }else if(props.formdata.formName==="New Task"){
          formTypeEnum="TASKS"
        }
        else if(props.formdata.formName==="Customer Details"){
          formTypeEnum="CUSTOMER"
        }
        else if(props.formdata.formName==="Worker Details"){
          formTypeEnum="WORKER"
        }
       
        let main_obj = {
          formRef: {
            id: props.formdata.id,
            name:props.formdata.formName,
          },
          organisationRef: {
            id: "mascot-footwear",
            name: "Mascot Footwear",
          },
          answers: [],
          formTypeEnum: formTypeEnum,
          source: "WEB_FORM",
          extraData: {},
          filledByUserProfile: null,
          feedbackBackRef: null,
          submissionDone: true,
        };
        if (!error) {
          for (let j = 1; j <= props.formdata.filledByInfos.length; j++) {

            if(updateform!==null){
              for(let x=0;x<updateform.answers.length;x++){
                if(props.formdata.filledByInfos[j - 1].fieldNo===updateform.answers[x].fieldNo){

                updateform.answers[x].value=rows[i][`col${j}`] }
                }

                



              }
            
            let ref_obj = {
              fieldNo: props.formdata.filledByInfos[j - 1].fieldNo,
              value: rows[i][`col${j}`],
              type: props.formdata.filledByInfos[j - 1].display.inputType,
              label: props.formdata.filledByInfos[j - 1].display.label,
              casePreserve: null,
              parentWidgetNo: 0,
              altValue: "",
              configId:props.formdata.filledByInfos[j - 1].configId ,
            };
            main_obj.answers.push(ref_obj);
          }
          if(props.formdata &&
          props.formdata.pages){
            setwidgetsub(main_obj);
            document.getElementById('loader').className="loading";
            
          

          }else if(!props.transaction || props.transaction===false){
            document.getElementById('loader').className="loading";
          
          try {
            setloading(true);
            setsubmit("submitting")
           
            const response = await fetch(
              " https://mascot-app.dotevolve.net/formFilled",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(main_obj),
              }
            );
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            const jsonResponse = await response.json();
            console.log(`order_details_row_${i + 1}_submitted`);
            console.log(jsonResponse);
            idArray.push(jsonResponse.id);
          } catch (error) {
            

          }

          let idValue = idArray.join(",");
          setvalue(idValue);
          if (Submit !== "Error!") {
          

            setTimeout(() => {
              setloading(false);
              setsubmit("Submitted Successfully");
              document.getElementById('loader').className="";
              for (let i = 0; i < rows.length; i++) {
                for (let j = 1; j <= props.formdata.filledByInfos.length; j++) {
                  if(document.getElementById(
                    `td${rows[i].id}col${j}${props.formdata.id}`
                  )!==null){
                  document.getElementById(
                    `td${rows[i].id}col${j}${props.formdata.id}`
                  ).disabled=false;
                }}}
              
            }, 2500)

          

           



            setPersonName([]);



            
          }
        }else if(props.transaction && props.transaction===true){
          let ref2_obj={
            paidBy: null,
            paidTo: {
                id: rows[0][`col${1}`],
                name:  rows[0][`col${2}`],
            },
            paidAmount: rows[0][`col${3}`],
            status:  rows[0][`col${4}`],
            paymentGateWayDetail: {
              usedPaymentGateWay: rows[0][`col${5}`],
                referenceId: rows[0][`col${6}`],
                bankTxnId: rows[0][`col${7}`],
                bankTxnTime: rows[0][`col${8}`],
                gateWayResponseLogs: []
            },
            description: rows[0][`col${9}`],
        }
        document.getElementById('loader').className="loading";
          
        try {
          setloading(true);
          setsubmit("submitting")
         
          const response = await fetch(
            " https://mascot-app.dotevolve.net/transaction",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(ref2_obj),
            }
          );
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          const jsonResponse = await response.json();
          console.log(jsonResponse);

          
        
        } catch (error) {
          

        }





        }
        setTimeout(() => {
          setsubmit("Submit");
          setloading(false);
          document.getElementById('loader').className="";
          for (let i = 0; i < rows.length; i++) {
            for (let j = 1; j <= props.formdata.filledByInfos.length; j++) {
              if(document.getElementById(
                `td${rows[i].id}col${j}${props.formdata.id}`
              )!==null){
              document.getElementById(
                `td${rows[i].id}col${j}${props.formdata.id}`
              ).disabled=false;
            }}}







          if (!error) {
            for (let i = 0; i < rows.length; i++) {
              let p = document.getElementsByTagName("select");
              for (let k = 0; k < p.length; k++) {
                p[k].selectedIndex = 0;
              }
              rows[i][`col${props.formdata.filledByInfos.length + 1}`] = 0;
              for (let j = 1; j <= props.formdata.filledByInfos.length; j++) {
                rows[i][`col${j}`] =
                  props.formdata.filledByInfos[j - 1].defaultValue;
                rows[i][`col${props.formdata.filledByInfos.length + 1}`] +=
                  parseInt(props.formdata.filledByInfos[j - 1].defaultValue) ||
                  0;
              }
            }
            let x = 0;
            for (let p = 0; p < rows.length; p++) {
              x +=
                parseInt(
                  rows[p][`col${props.formdata.filledByInfos.length + 1}`]
                ) || 0;
            }

            setMain(x);
            let a = 1;
            let ref_obj2 = {};

            for (let i = 1; i <= props.formdata.filledByInfos.length; i++) {
              if (rows.length === 0) {
                for (let i = 1; i <= 7; i++) {
                  ref_obj2[`col${i}`] = 0;
                }
              } else {
                let flag = 0;
                let z = 0;
                for (let p = 0; p < rows.length; p++) {
                  if (
                    props.formdata.filledByInfos[i - 1].display.inputType ===
                    "NUMBER"
                  ) {
                    z += parseInt(rows[p][`col${i}`]) || 0;
                    flag = 1;
                  }
                }

                if (flag === 1) {
                  ref_obj2[`col${a++}`] = z;
                }
              }
            }

            setTotal(ref_obj2);
          }
        }, 3500);}
      }
  
    
    
    }
  
   }

  function updateTotal(row, columnIndex, newValue, { inputType },error , regex) {
  console.log(regex)
    
    
   

    let value = "";
    if (inputType === "select") {
      value = newValue;
    } else {
      if (newValue.length === 0) {
        if (inputType === "number") {
          value = "";
        } else {
          value = "";
        }
      } else if (newValue.match(regex)) {
        value = newValue;
      } else {
        value = row[`col${columnIndex}`];
      }
    }
    if (value.length === 0) {
      document.getElementById(
        `${row.id}col${columnIndex}${props.formdata.id}`
      ).innerText = error;

      document.getElementById(
        `td${row.id}col${columnIndex}${props.formdata.id}`
      ).style.border = "thin solid red";
    } else {
      // document.getElementById(`td${row.id}col${columnIndex}${props.formdata.id}`).style.border = "thick solid green";
      document.getElementById(
        `${row.id}col${columnIndex}${props.formdata.id}`
      ).innerText = "";
      document.getElementById(
        `td${row.id}col${columnIndex}${props.formdata.id}`
      ).style.border = "none";
    }
    if (value.length < 50) {
      const updatedRows = rows.map((r) =>
        r.id === row.id
          ? {
              ...r,

              [`col${columnIndex}`]: value,
            }
          : r
      );
      setRows(updatedRows);
      let total = 0;
      for (let i = 1; i <= props.formdata.filledByInfos.length; i++) {
        if (
          props.formdata.filledByInfos[i - 1].display.inputType === "NUMBER"
        ) {
          total +=
            parseInt(updatedRows.find((r) => r.id === row.id)[`col${i}`]) || 0;
        }
      }
      if (props.total === "total") {
        setRows((prevRows) =>
          prevRows.map((r) => {
            if (r.id === row.id) {
              r[`col${props.formdata.filledByInfos.length + 1}`] = total;
            }
            return r;
          })
        );
      }
    }
  }

  return (
    <>
    <div id="loader" class="fluid-container"></div>

<div class="content">
      <div key ={"main"}className="container-fluid d-flex flex-column text-center" >
 
          
        { 
             <Heading class="p-2"
             form={props.formdata}
             value={value1}
             p={props.p}
             redirect={props.redirect}
           />
        }
        {props.formdata &&
          props.formdata.id &&
          props.formdata.filledByInfos &&
          props.p === 0 && (
            <div className=" flex-box text-center justify-content-center">
             
                
              <table
                id="data"
                className="table-responsive data-table data-table-horizontal data-table-highlight"
             
              >  <div className="main">

            
                <tbody className="table-responsive flex-box text-center justify-content-center" style={
              { 
                width:"min-content",
                height:"max-height",
                marginRight:"auto",
                marginLeft:"auto",
              }
              } >
           
                  <Header 
                    data={props.formdata.filledByInfos}
                    total={props.total}
                  />
                  {rows.map((row) => (
                    <tr key={row.id} style={{borderRadius:"25px",
                     
                    margin:"3px",
                  
                    opacity:"2"
                    
                    }} >
                      {Array.from(
                        { length: props.formdata.filledByInfos.length + 1 },
                        (_, i) => {
                          const columnIndex = i + 1;
                          let inputType = "";
                          let error="";
                          let hidden="";
                          let regex="";

                          if (i < props.formdata.filledByInfos.length) {
                            if(props.formdata.filledByInfos[i].hidden){
                              hidden=props.formdata.filledByInfos[i].hidden;}

                              if(props.formdata.filledByInfos[i].constraints && props.formdata.filledByInfos[i].constraints.allowInputRegex){
                              
                                regex = new RegExp(props.formdata.filledByInfos[i].constraints.allowInputRegex + '+$');
                              
                            }else{
                              regex = /^[a-zA-Z]+$/;
                            }


                            if(props.formdata.filledByInfos[i].validation.errorMessage!==null){
                              error=props.formdata.filledByInfos[i].validation.errorMessage;
                            }
                            if (
                              props.formdata.filledByInfos[i].display
                                .inputType === "NUMBER"
                            ) {
                              if (props.formdata.filledByInfos[i].constraints)
                                inputType = "number";
                            } else if (
                              props.formdata.filledByInfos[i].display
                                .inputType === "TEXT"
                            ) {
                              inputType = "text";
                            } else if (
                              props.formdata.filledByInfos[i].display
                                .inputType === "DROPDOWN" 
                            ) {
                              inputType = "select";
                            } else if(props.formdata.filledByInfos[i].display
                              .inputType === "MULTI_SELECT_CHECKBOX"){
                              inputType = "multiselectcheckbox";
                              if( props.formdata.filledByInfos[i].configId==="departmentsList"){
                                multirender(props.formdata.filledByInfos[i].configId);
                                
                              }
                            
                            }else{
                              inputType="number"
                            }
                          } else if (props.total === "total") {
                            inputType = "number";
                          }
                          // }
                          return inputType === "select"||inputType==="multiselectcheckbox"? (

                      

                           ( ((hidden===""||hidden===false) &&inputType === "select")?(
                            <td key={`col${columnIndex}`}  >
                              <select

                                className=" p-2 my-1 dropdown"
                                
                                id={`td${row.id}col${columnIndex}${props.formdata.id}`}

                                onFocus={() => {
                                  document.getElementById(`td${row.id}col${columnIndex}${props.formdata.id}`).style.border = "thin solid green";


                                }}
                              
                                
                                onChange={(e) => {
                                  updateTotal(
                                    row,
                                    columnIndex,
                                    e.target.value,
                                    {
                                      inputType,
                                    },
                                    error,
                                    regex                                
                                  );
                                  
                                    
                                     
    
    
                                    
                                  
                                }}
                              >
                                <option value="" selected>
                                  Select your{" "}
                                  {
                                    props.formdata.filledByInfos[i].display
                                      .label
                                  }{" "}
                                </option>

                                <Dropper 
                                  configid={
                                    props.formdata.filledByInfos[i].configId
                                  }
                                />
                              </select>
                              <p
                                className="hey1"
                                style={{padding:"10px",
                              height:"10px"}}
                                key={props.formdata.id}
                                id={`${row.id}col${columnIndex}${props.formdata.id}`}
                              ></p>
                            </td>
                            
                           ):  ( hidden===""||hidden===false )&& inputType === "multiselectcheckbox"&&
                            
                           <td key={`col${columnIndex}`}    >

<FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">select your department</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
        
        
          multiple
          value={personName}
          onChange={handleChange

          }
        
          
          

          
          input={<OutlinedInput label="select your department" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            
            <MenuItem key={name} value={name}>
              <Checkbox checked={personName.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>



     <p
                               className="hey1"
                               style={{padding:"10px",
                             height:"10px"}}
                               key={props.formdata.id}
                               id={`${row.id}col${columnIndex}${props.formdata.id}`}
                             ></p>
                           </td>
                           

                            

                            
                           )
                         
                        
                            
                          ) : (
                            inputType  && (hidden===""||hidden===false)&&(
                              <td key={`col${columnIndex}`} >
                                
                                <input 
                                                                  style={{     backgroundColor: "#e7f1f1",}}
                                  type={inputType}
                                  className="p-2 col-sm-10"
                                  id={`td${row.id}col${columnIndex}${props.formdata.id}`}
                                  onFocus={() => {
                                    document.getElementById(`td${row.id}col${columnIndex}${props.formdata.id}`).style.border = "2px solid green";
  
  
                                  }}
                                  value={row[`col${columnIndex}`]}
                                  onChange={(e) => {
                                    updateTotal(
                                      row,
                                      columnIndex,
                                      e.target.value,
                                      {
                                        inputType,
                                      },
                                      error,
                                      regex
                                    );
                                  }}
                                />
                                <p key={props.formdata.id}
                                  className="hey1"
                                  id={`${row.id}col${columnIndex}${props.formdata.id}`}
                                ></p>
                              </td>
                            )
                          );
                         
                        
                          
                        }
                      )}
                      {props.total === "total" && 
                      <td className="texx">
                        {row.id !== 0 ? (
                          <MdDelete 
                            
                            onClick={() => deleteRow(row.id)}
                          />
                        ) : (
                          <MdDelete value="null"  />
                        )}
                      </td>}
                    </tr>
                  ))}

                  {props.total === "total" && (
                    <tr style={{borderRadius:"25px",
                 
                  
                    opacity:"2"
                    
                    }}>
                      
                      <td className="hey"></td>
                      <td className="hey"></td>
                      <td className="hey"></td>
                      <td className="hey"></td>
                      <td className="hey"></td>
                      <td className="hey"></td>
                      <td className="hey"></td>
                      <td className="hey"></td>
                      <td className="hey"></td>
                      <td className="hey" style={{zIndex:'1000'}}> 
                      <IconButton color="primary" component="label"  onClick={addRow} size="small">
  
  <DataSaverOnOutlinedIcon /> {" Row"}
</IconButton>
                      
                     </td>
                      <td className="respective_totals">
                        {" "}
                        <h2>Total:</h2>
                      </td>
                      <td className="hey">
                        <input type="number" value={Main_total} />
                      </td>
                    </tr>
                  )}
                  
                
                  
                </tbody>
                </div>
        
                <div className="flex-box text-center justify-content-center">
                {props.formdata &&
          props.formdata.pages &&
          props.formdata.pages.map((field) =>
      
              
            
                   
                    <Widget class="p-2" formdata={field.inputWidgets}
                    obj={widgetsub}
                    update_form={updateform}
                      checksubmission={checksubmission}
                    />
                  
              )
      }
        
                <div className=" p-4  text-white  width">
                <LoadingButton id={props.formdata.id}
      onClick={ ()=>{handleSubmit(props.formdata.id)}}
      style={{fontSize:'20px',
      borderRadius:'200px'
      ,
      color:'black',
      backgroundColor:'#e7f1f1',
      marginLeft:'13px'
    
    }}
  loading={loading}
      
          endIcon={<SendIcon />}
          loadingPosition="end"
          variant="contained"
        >
          {Submit}
        </LoadingButton>
              
              
              </div>

                </div>
              </table>
       
              
              
             
            </div>
          )}
          
        {props.formdata &&
          props.formdata.pages &&
          props.formdata.pages.map((field) =>
            field.inputWidgets.map((help) =>
              help.questions.map(
                (root) =>
                  root.display.inputType === "OPEN_ANOTHER_FORM" && (
                    <div className="flex-box">
                    <Receipt
                      formid={root.openAnotherForm.formId}
                      redirect={true}
                      total={props.total}
                      
                    />
                    </div>
                  )
              )
            )
          )}
         
      </div>
      </div>
   
    </>
  );
}

export default Table;
