import React from "react";

function Header(props) {
  return (
    <tr className="head" key={0} style={{borderRadius:"25px",
    backgroundColor:"white",
  
    opacity:"2"
    
    }}>
      {props.data.map((field) => (

        field.hidden===false&&
        <th id={field.fieldNo} className=" text-dark main_heading" style={{
         
        marginLeft:"10px"
        
        }}>
          {field.display.label}
        </th>
      ))}
      {
      props.total==="total"&&
      <th id ="total_col"className="main_heading" style={{color:"black"}}>Total</th>
      
      }
      <td className="main_heading"></td>

    </tr>
  );
}

export default Header;
