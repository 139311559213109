import React from 'react'
import { useEffect,useState } from 'react';
import { MdDelete } from "react-icons/md";
import {BsPlusLg} from "react-icons/bs"
function Modal(props) {
  
    const [rows, setRows] = useState([]);
    const[item,setItem]=useState("")
    const[data,setdata]=useState(0);
  
    const [length,setLength]=useState(0);
    function addRow() {
        let newRow = { id: Date.now() };
        
        if (length) {
          for (let i = 1; i <= length; i++) {
            newRow[`col${i}`] = "";
            if(i===1){
                newRow[`col${i}`] = "";  
            }
            
          }
        }
        setRows([...rows, newRow]);
      
      }

      async function handleSubmit() {
      
        
        if(length===3){
          let error=false;
          for (let i = 0; i < rows.length; i++) {

            if(rows[i]["col2"]===""){
              error=true;
              document.getElementById(`tddd${rows[i].id}col${2}`).innerText="field Can't be empty";
              document.getElementById(
                `color${rows[i].id}col${2}`
              ).style.border = "thin solid red";

            }
            if(rows[i]["col3"]===""){
              error=true;
              document.getElementById(`tddd${rows[i].id}col${3}`).innerText="field Can't be empty";
              document.getElementById(
                `color${rows[i].id}col${3}`
              ).style.border = "thin solid red";

            }
          }

            
            for (let i = 0; i < rows.length && error===false; i++) {

        
                 let obj=   {
                        configId: "colorsList",
                        providerId: "mascot-footwear",
                        orderBy: 1,
                        label: rows[i]["col2"],
                        value: rows[i]["col1"],
                        altValue: null
                    }

                    try {
                        const response = await fetch(
                          " https://mascot-app.dotevolve.net/fieldConfig",
                          {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                            },
                            body: JSON.stringify(obj),
                          }
                        );
                        if (!response.ok) {
                          throw new Error(response.statusText);
                        }else{
                            console.log("done")

                            props.onchildclick(data+1);
                           
                            setdata(data+1)

                            
                              rows[i]['col1']="";
                              rows[i]['col2']="";
                              rows[i]['col3']="";
                              
                        
                            

                       

                  
                            
                            
                        }


                }catch(error){
                    console.log(error)
                    console.log(item)
                }











        }






      }else{
        let error=false;
        for (let i = 0; i < rows.length; i++) {

          if(rows[i]["col1"]===""){
            error=true;
            document.getElementById(`tddd${rows[i].id}col${1}`).innerText="field Can't be empty";
            document.getElementById(
              `color${rows[i].id}col${1}`
            ).style.border = "thin solid red";

          }
          if(rows[i]["col2"]===""){
            error=true;
            document.getElementById(`tddd${rows[i].id}col${2}`).innerText="field Can't be empty";
            document.getElementById(
              `color${rows[i].id}col${2}`
            ).style.border = "thin solid red";

          }
        }
        for (let i = 0; i < rows.length && error===false; i++) {
            let configId="";
              if(props.item==='Material'){
                configId="materialsList"
              }else{
                configId="solesList"
              }
        
            let obj=   {
                   configId: configId,
                   providerId: "mascot-footwear",
                   orderBy: 1,
                   label: rows[i]["col2"],
                   value: rows[i]["col1"],
                   altValue: null
               }

               try {
                   const response = await fetch(
                     " https://mascot-app.dotevolve.net/fieldConfig",
                     {
                       method: "POST",
                       headers: {
                         "Content-Type": "application/json",
                       },
                       body: JSON.stringify(obj),
                     }
                   );
                   if (!response.ok) {
                     throw new Error(response.statusText);
                   }else{
                       console.log("done")
                       props.onchildclick(data+1);

                       
                      
                           
                       setdata(data+1)
                      
                        rows[i]['col1']="";
                        rows[i]['col2']="";
                        
                    
                   }


           }catch(error){
               console.log(error)
           }











   }
      }
    
    }


  useEffect((()=>{
    if(props.item!==null){
        if(props.item==="Color"){
            setLength(3);
        }else{
            setLength(2);
        }
        setItem(props.item);
    }

  }),[props])
 

  useEffect(() => {
    if (length!==0) {
      let array = [];
      for (let i = 0; i < 1; i++) {
        let obj = {};
    
        for (let j = 1; j <= length; j++) {
            if(j===1){
                obj[`col${j}`] = "";
            }else{
                obj[`col${j}`] = "";
            }
          
          
        }
       
        array.push({
          id: i + 1,
          ...obj,
        });

        setRows(array);
      }
    }
  }, [length]);
  function deleteRow(id) {
    setRows(rows.filter((row) => row.id !== id));
  }
  function handlecolorsubmit(value,row,columnindex){
  
    
   
    if(columnindex===3){ 
        let hexColor = (value).trim();

if(hexColor.indexOf('#') !== -1){

        document.getElementById(`color${row.id}col${columnindex}`).style.Color={hexColor};
    console.log(hexColor)}
  
    const updatedRows = rows.map((r) =>
    r.id === row.id
      ? { 
          ...r,
          
          [`col${columnindex}`]: value,
          [`col${1}`]: value,
          
        }
      : r
  );
  setRows(updatedRows);
  if(value===""){
    
    document.getElementById(`tddd${row.id}col${2}`).innerText="field Can't be empty";
    document.getElementById(
      `color${row.id}col${3}`
    ).style.border = "thin solid red";

  }else{
    document.getElementById(`tddd${row.id}col${3}`).innerText="";
    document.getElementById(
      `color${row.id}col${3}`
    ).style.border = "";

  }


}else if(columnindex===2){
    const updatedRows = rows.map((r) =>
    r.id === row.id
      ? {
          ...r,

          [`col${columnindex}`]: value,
          
        }
      : r
  );
  setRows(updatedRows);
  

    if(value===""){
    
      document.getElementById(`tddd${row.id}col${2}`).innerText="field Can't be empty";
      document.getElementById(
        `color${row.id}col${2}`
      ).style.border = "thin solid red";

    }else{
      document.getElementById(`tddd${row.id}col${2}`).innerText="";
      document.getElementById(
        `color${row.id}col${2}`
      ).style.border = "thin solid green";

    }
   
  


  }
  


  }
  function othersubmission(value,row,columnindex){

    const updatedRows = rows.map((r) =>
    r.id === row.id
      ? { 
          ...r,
          
          [`col${columnindex}`]: value,
        
          
        }
      : r
  );
  setRows(updatedRows);
  if(value===""){
    
    document.getElementById(`tddd${row.id}col${columnindex}`).innerText="field Can't be empty";
    document.getElementById(
      `color${row.id}col${columnindex}`
    ).style.border = "thin solid red";

  }else{
    document.getElementById(`tddd${row.id}col${columnindex}`).innerText="";
    document.getElementById(
      `color${row.id}col${columnindex}`
      ).style.border = "thin solid green";

  }

  }
   
 
  return (
    <div className='container'>
        {
            length===3 ?(
            <div className='flex-box'>
                 <table
                id="data1"
                className="table-responsive p-2 data-table data-table-horizontal data-table-highlight"
              > 
                
                <tbody className='flex-box'>
                    <tr>
                <th style={{
                  color:'grey'
                }}>
                        Color Id
                    </th>
                    <th>
                        Color 
                    </th>
                    <th>
                        Color Picker
                    </th>
                    </tr>
                   
                    { rows.map((row) => (
                            <tr key={row.id} id={row.id}>
                            {Array.from(
                                { length: 3 },
                                (_, i) => {
                            const columnIndex = i + 1;
                            let inputType="text";
                            let disabled=null;
                            if(i===2 ){
                                inputType="color";
                            }
                            if(i===0){
disabled="disabled";
                            }
        
                                
                            // }
                            return (
                                <td key={`col${columnIndex}`} className="col-2">
                              {
                                
                                <input class="col-sm"
                                //   style={{  backgroundColor: "#aee1e1",}}
                                  type={inputType}
                                  id={`color${row.id}col${columnIndex}`}
                                  value={row[`col${columnIndex}`]}
                                  onChange={((e)=>{handlecolorsubmit(e.target.value,row,columnIndex)})}
                                 disabled={disabled}
                                
                                />}
                                <p key={1}
                                  className="hey1"
                                  id={`tddd${row.id}col${columnIndex}`}
                                ></p>
                              </td>
                                
                                );
                            }
                                )}
                                  <td className="texx">
                        {row.id !== 0 ? (
                          <MdDelete 
                            
                             onClick={() => deleteRow(row.id)}
                          />
                        ) : (
                          <MdDelete value="null"  />
                        )}
                      </td>
                            </tr>
                            ))}

                </tbody>

              </table>
              <div className=" p-2 text-center">
                <button
                 class=" btn btn-primary"
                  type="button"
                  
                onClick={() => handleSubmit()}
                >
                  <span className="mr-auto p-2submit-button__text flex-box">Submit</span>
                </button>
                <button className="btn btn-light" style={{fontSize:'20px',
                borderRadius:'200px'
                ,
                marginLeft:'14px'
              
              }} onClick={addRow}>
                <BsPlusLg  />
              
              </button>
              </div>
              
             
            </div>):(
                 <div className='flex-box'>
                 <table
                id="data1"
                className="table-responsive p-2 data-table data-table-horizontal data-table-highlight"
              > 
                
                <tbody className='flex-box'>
                    <tr>
                <th>
                        {props.item} Id
                    </th>
                    <th>
                        {props.item} Name
                    </th>
                    
                    </tr>
                   
                    { rows.map((row) => (
                            <tr key={row.id} id={row.id}>
                            {Array.from(
                                { length: 2 },
                                (_, i) => {
                            const columnIndex = i + 1;
                            let inputType="text";
                          
        
                                
                            // }
                            return (
                                <td key={`col${columnIndex}`} className="col-2">
                                
                                <input class="col-sm"
                                //   style={{  backgroundColor: "#aee1e1",}}
                                  type={inputType}
                                  id={`color${row.id}col${columnIndex}`}
                                  value={row[`col${columnIndex}`]}
                                  onChange={((e)=>{othersubmission(e.target.value,row,columnIndex)})}
                                 
                                />
                                <p key={1}
                                  className="hey1"
                                  id={`tddd${row.id}col${columnIndex}`}
                                ></p>
                              </td>
                                
                                );
                            }
                                )}
                                  <td className="texx">
                        {row.id !== 0 ? (
                          <MdDelete 
                            
                             onClick={() => deleteRow(row.id)}
                          />
                        ) : (
                          <MdDelete value="null"  />
                        )}
                      </td>
                            </tr>
                            ))}

                </tbody>

              </table>
              <div className=" p-2 text-center">
                <button
                 class=" btn btn-primary"
                  type="button"
                  
                onClick={() => handleSubmit()}
                >
                  <span className="mr-auto p-2submit-button__text flex-box">Submit</span>
                </button>
                <button className="btn btn-light" style={{fontSize:'20px',
                borderRadius:'200px'
                ,
                marginLeft:'14px'
              
              }} onClick={addRow}>
                <BsPlusLg  />
              
              </button>
              </div>
              
             
            </div>

            )
                
        }


    </div>


 
    
  )
}

export default Modal