import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import "./receipt.css";
function Dropper(props) {
  var [dropperdata, setForm] = useState(null);
  
  useEffect(() => {
    async function fetchForm() {
      try {
        const headers = { "Content-Type": "application/json" };
        const body = {
          providerId: "mascot-footwear",
          configId: `${props.configid}`,
        };
        const response = await axios.post(
          " https://mascot-app.dotevolve.net/fieldConfig/search",
          body,
          { headers }
        );

        setForm(response.data);

        if (response.data != null) {
        }
      } catch (error) {}
    }
    fetchForm();
},[props.configid]);

  
  return (
    dropperdata &&
    dropperdata.map((field) => (
      <option key={field.id} value={field.value}>
        {field.label}
      </option>
    ))
  );
}

export default Dropper;
